












import { defineComponent, reactive, toRefs } from "@vue/composition-api"
import Qrcode from "@/components/Qrcode.vue"
import SpaceObjectLocation from "@/components/SpaceObjectLocation.vue"
import KeywordInput from "@/components/KeywordInput.vue"
import {
    page,
    itemsPerPage,
    keyword,
    buildNumberFilter,
} from "@/services/QueryParmsService"
import { QrCodeSortMethod, getQrCodes } from "@/services/SpaceObjectsService"
import { env } from "@/env"

export default defineComponent({
    name: "QrCodePrint",
    components: {
        KeywordInput,
        Qrcode,
        SpaceObjectLocation,
    },
    setup() {
        const sortMethod = buildNumberFilter("s", QrCodeSortMethod.Id)
        const state = reactive({
            items: [] as {
                id: string
                subject: string
            }[],
            loading: false,
        })

        async function loadData() {
            state.loading = true
            const { items } = await getQrCodes({
                keyword: keyword.value,
                sort: sortMethod.value,
            })
            state.items = items
            state.loading = false
        }

        loadData()

        function genQrCodePayload(id: string) {
            return `${env.QR_CODE_LINK_PREFIX}${id}`
        }

        return {
            ...toRefs(state),
            page,
            itemsPerPage,
            loadData,
            sortMethod,
            genQrCodePayload,
        }
    },
})
